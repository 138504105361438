$(document).ready(function() {
  'use strict';

  // if ($('.paragraphs-item-accordion .para-jq-acc').length) {
  //   $('.para-jq-acc').accordion({
  //     header: 'h3',
  //     collapsible: true,
  //     active: false,
  //     heightStyle: 'content',
  //     beforeActivate: function() {
  //       // Prevent jQuery UI accordion from closing other panels
  //       return false;
  //     }
  //   });

  //   // Custom click handler to control the opening and closing of panels
  //   $('.para-jq-acc h3').on('click', function(event) {
  //     const panel = $(this).next('.ui-accordion-content');

  //     if (panel.is(':visible')) {
  //       panel.slideUp();
  //       $(this).removeClass('ui-accordion-header-active ui-state-active');
  //     }
  //     else {
  //       panel.slideDown();
  //       $(this).addClass('ui-accordion-header-active ui-state-active');
  //     }

  //     // Prevent the default accordion behavior
  //     event.preventDefault();
  //     event.stopImmediatePropagation();
  //   });
  // }

});
