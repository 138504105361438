!((document, $) => {
  'use strict';

  /**
   * Set the width of search form
   */
  function searchFormWidthSet() {
    if (window.innerWidth >= 992) {
      var of = $('.block-menu-menu-give-menu').offset(); // this will return the left and top
      var left = of.left; // this will return left
      var right =
        document.body.scrollWidth -
        left -
        $('.block-menu-menu-give-menu').width(); // you can get right by calculate
      var width = parseInt(right.toFixed()) + 98;
      if ($('.search-block-form .form-type-search').length) {
        $('.search-block-form .form-type-search').css({
          width: width
        });
      }

      //set search icon left side of search form
      var searchIconRight = width - 40;
      $('.search-block-form .form-actions').css({ right: searchIconRight });
    }
    else {
      if ($('.search-block-form .form-type-search').length) {
        $('.search-block-form .form-type-search').css({
          width: '100%'
        });
      }
      $('.search-block-form .form-actions').css({ right: 'auto' });
    }
  }

  // Header top menu move to main menu
  $(document).ready(function() {
    $('<i class="fa fa-search"></i>').prependTo(
      '.search-block-form .form-actions'
    );

    $('.l-header .search-block-form #edit-submit').click(function() {});

    searchFormWidthSet();

    $('.search-block-form .form-type-search .form-search').focus(function() {
      $('.block-search-form .search-box .form-actions').addClass('typed');
    });

    $('.search-block-form .form-type-search .form-search').focusout(function() {
      $('.block-search-form .search-box .form-actions').removeClass('typed');
    });

    $(window).resize(function() {
      searchFormWidthSet();
    });
  });
})(document, jQuery);
