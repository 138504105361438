!((document, $) => {
  'use strict';

  // Give menu
  $(document).ready(function() {
    if ($('.annual-report').length) {
      $('body').addClass('annual-report-page');

      if ($('.annual-report .pager .first').hasClass('pager-current')) {
        $('.annual-report .views-row.first').addClass('first-report');
      }
    }
  });
})(document, jQuery);
