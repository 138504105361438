!((document, $) => {
  'use strict';

  // Give menu
  $(document).ready(function() {
    $('.menu a.nolink').click(function(event) {
      event.preventDefault();
    });
  });
})(document, jQuery);
