!((document, $) => {
  'use strict';

  // Tabs functionalities
  $(document).ready(function() {
    $('.tab_wrapper .nav-tabs > li a').click(function(event) {
      event.preventDefault();
      var activeItem = $(this).attr('href');

      // Deactivate tab-contents
      $(this)
        .parents('.tab_wrapper')
        .find('li')
        .removeClass('active');
      $(this)
        .parent('li')
        .addClass('active');

      // Activate target tab-content
      $(this)
        .parents('.tab_wrapper')
        .find('.tab-pane')
        .removeClass('in active');
      $(activeItem).addClass('in active');
    });
  });
})(document, jQuery);
